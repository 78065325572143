import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const Navigation = () => {
    const links = [ {path: '/', name: 'Home'}, {path: '/about', name: 'About us'}, {path: '/classes', name: 'CLasses'},{path: '/blog', name: 'BLog'}, 
                    {path: '/contact', name: 'Contact'}, {path: '/login', name: 'Log in'}, {path: '/signup', name: 'Sign up'}]

    const navLinks = useState(links);
    
    const linkStlye = {background: '#989898', margin: '10px 5px', padding: '10px'} 

    const generateBtn = links => {
        return links.map((link, index) => {
            return (
                <span style={linkStlye} key={index}>
                    <Link to={link.path} >
                        {link.name} 
                    </Link>
                </span>
            );
        })
    }
    return (
        <>
            <div style={linkStlye}>
                {generateBtn(navLinks[0]) }
            </div>
        </>
    );
}

export default Navigation;