
import React from 'react'

const Home = () => {
    return (
        <>
        Hello world !!
        </>
    );
}

export default Home;